<template>
  <div class="booking-summary">
    <div class="price-info flex items-center gap-2 mt-1">
      <span class="text-lg">{{ $t('total_billing_amount') }}</span>
      <div class="detail flex items-center">
        <span v-if="isBookingPreviewPage" class="text-yellow-50 text-lg font-500">{{ txnAmountText }}</span>
        <span v-else class="text-yellow-50 text-lg font-500">{{ props.product?.currency_code }} {{ priceFormat(totalPrice) }}</span>
        <nd-button type="text" class="active" size="small" icon-position="right" @click="handleDetailClick">
          <div class="flex items-center gap-1">
            <span>{{ $t('all_show_detail') }}</span>
            <svg-icon name="arrow-down" class="w-5 h-5 transition-transform" :class="[isShowBookingDetail && 'rotate-180']" />
          </div>
        </nd-button>
      </div>
    </div>
    <div class="list transition-height h-0 overflow-hidden" :class="[isShowBookingDetail && 'h-auto']">
      <div v-if="props.product.is_crossday" class="booking-item-list">
        <div v-for="item in uiBookingItem" :key="item.dateTime" class="upgrade-item border-b-1 border-gray-40 flex gap-3 justify-between py-4">
          <div class="font-500">{{ getFormatDateString(item.dateTime) }}</div>
          <div class="flex-1">{{ getFormatWeekdayString(item.dateTime) }}</div>
          <div class="min-w-fit whitespace-nowrap">{{ props.product?.currency_code }} {{ priceFormat(item.price) }}</div>
        </div>
      </div>
      <div v-else class="upgrades-list">
        <div v-for="upgrade in upgrades" :key="upgrade.upgrade_id" class="upgrade-item border-b-1 border-gray-40 flex gap-3 justify-between py-4">
          <div class="font-500">{{ upgrade.count }}x</div>
          <div class="flex-1">{{ upgrade?.name }}</div>
          <div class="min-w-fit whitespace-nowrap">{{ props.product?.currency_code }} {{ priceFormat(upgrade?.amount) }}</div>
        </div>
      </div>
      <div class="additions-list">
        <div v-for="addition in additions" :key="addition.pid" class="addition-item border-b-1 border-gray-40 flex gap-3 justify-between py-4">
          <div class="font-500">{{ addition.count }}x</div>
          <div class="flex-1">{{ addition?.name }}</div>
          <div class="min-w-fit">{{ props.product?.currency_code }} {{ priceFormat(addition?.amount) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { parseISO } from 'date-fns'
import type { UiProductInfo, UiProductSelectedData } from '@/types/product'
import type { ProductUpgradeResource, ProductAdditionsResource } from '@/types/booking'

const { format } = await useI18nDateFns()

const props = defineProps<{
  product: UiProductInfo
  bookingItem: UiProductSelectedData[]
  additions: ProductAdditionsResource[]
  upgrades: ProductUpgradeResource[]
}>()

const { txnAmountText } = usePaymentCurrency()
const route = useRoute()
const isBookingPreviewPage = computed(() => route.name?.toString().includes('booking-pid-preview'))

const isShowBookingDetail = ref(false)

const additionsTotalPrice = computed(() => (props.additions?.length ? props.additions.reduce((acc, a) => acc + Number(a?.amount || 0), 0) : 0))
const upgradesTotalPrice = computed(() => (props.upgrades?.length ? props.upgrades.reduce((acc, u) => acc + Number(u?.amount || 0), 0) : 0))
const totalPrice = computed(() => additionsTotalPrice.value + upgradesTotalPrice.value)

const uiBookingItem = computed(() => {
  return props.bookingItem.length > 1 ? props.bookingItem.slice(0, -1) : props.bookingItem
})

const handleDetailClick = () => {
  isShowBookingDetail.value = !isShowBookingDetail.value
}

const getFormatDateString = (dateTime: string) => {
  return format(parseISO(dateTime), 'MMM do')
}
const getFormatWeekdayString = (dateTime: string) => {
  return format(parseISO(dateTime), 'EEE')
}
</script>

<style lang="scss" scoped>
.reservation-summary {
  container-type: inline-size;
  .price-info {
    @apply flex flex-col items-start text-sm whitespace-nowrap;
    button {
      @apply text-sm;
    }
  }
  @container (min-width: 400px) {
    .price-info {
      @apply flex flex-row items-center gap-2 text-lg;
      button {
        @apply text-base;
      }
    }
  }
}
</style>
