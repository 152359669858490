<template>
  <div id="layout-booking">
    <navbar class="fixed top-0 w-full z-navbar" />
    <main class="pt-18 pb-10 relative bg-gray-20 md:pt-22">
      <div class="container mx-auto">
        <div class="flex justify-center relative gap-6">
          <div class="page-wrapper w-full md:pb-0">
            <slot></slot>
          </div>
          <booking-sidebar v-if="isShowSidebar" />
        </div>
      </div>
      <simple-content-viewer
        v-model:show="modalStore.show"
        v-bind="{ ...modalStore.$state, onCancel: modalStore.onCancel, onConfirm: modalStore.onConfirm }"
      ></simple-content-viewer>
    </main>

    <global-alert />

    <!-- <json-ld-organization />
          <json-ld-website /> -->
  </div>
</template>

<script setup>
// import { storeToRefs } from 'pinia'
import Navbar from '@/components/common/navbar.vue'
import GlobalAlert from '@/components/common/global-alert.vue'
import SimpleContentViewer from '@/components/common/simple-content-viewer.vue'
import BookingSidebar from '@/components/booking/booking-sidebar.vue'

import { useAppStore } from '@/store/app'
import { useRegionStore } from '@/store/region'
// import { useAlertStore } from '@/store/alert'
import { useBookingStore } from '@/store/booking'
import { useModalStore } from '@/store/modal'

useSeoMeta({
  robots: 'noindex',
})

// fetch global data
const regionStore = useRegionStore()
const bookingStore = useBookingStore()
const modalStore = useModalStore()

const { regionId } = useRegion()

await useAsyncData('regionList', regionStore.fetchRegionList)
await useAsyncData(
  `region_${regionId.value}`,
  async () => {
    if (typeof regionId.value !== 'number') return {}

    const region = await regionStore.fetchRegion(regionId.value)
    return region
  },
  { watch: [regionId] }
)

const app = useAppStore()

const isShowSidebar = computed(() => app.isDesktopScreen)

onUnmounted(() => {
  bookingStore.$reset()
})
</script>

<style lang="scss" scoped></style>
