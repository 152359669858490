<template>
  <div
    class="booking-sidebar sticky flex shrink-0 flex-col gap-5 bg-white rounded p-5 h-full w-374px xl:w-512px transition-top overflow-auto no-scrollbar max-h-[calc(100dvh-128px)] top-22"
  >
    <div class="hero-image relative">
      <nd-img class="w-full aspect-1.8 object-cover rounded" :src="`fn:${product?.cover_image}`" :alt="product?.product_name" />
    </div>
    <booking-info :product="product!" :booking-item="bookingItem" :upgrades="upgrades" />
    <hr />
    <booking-summary :product="product!" :booking-item="bookingItem" :additions="additions" :upgrades="upgrades" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

import BookingInfo from '@/components/booking/booking-info.vue'
import BookingSummary from '@/components/booking/booking-summary.vue'

import { useBookingStore } from '@/store/booking'

const bookingStore = useBookingStore()

const { product, bookingItem, additions, upgrades } = storeToRefs(bookingStore)
</script>

<style lang="scss" scoped></style>
